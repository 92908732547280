var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _vm.rop !== "new"
                  ? _c("asyent-form-info", {
                      attrs: {
                        "view-mode": _vm.viewMode,
                        "no-title": "",
                        color: "cardPrimary",
                        "no-maximize": ""
                      },
                      model: {
                        value: _vm.formDataF,
                        callback: function($$v) {
                          _vm.formDataF = $$v
                        },
                        expression: "formDataF"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.typeReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "User Type",
                                        mandatory: "",
                                        name: "type",
                                        sortables: ""
                                      },
                                      model: {
                                        value: _vm.formDataF["type"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formDataF, "type", $$v)
                                        },
                                        expression: "formDataF['type']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.groupsReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        label: "User Group",
                                        mandatory: "",
                                        name: "group",
                                        "show-item-code": "",
                                        sortable: ""
                                      },
                                      model: {
                                        value: _vm.formDataF["group"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formDataF, "group", $$v)
                                        },
                                        expression: "formDataF['group']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.statusReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "User Status",
                                        mandatory: "",
                                        name: "status",
                                        sortable: ""
                                      },
                                      model: {
                                        value: _vm.formDataF["status"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formDataF, "status", $$v)
                                        },
                                        expression: "formDataF['status']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.isOfficerAccount ||
                                _vm.isServiceAccount ||
                                _vm.isUserAccount
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", lg: "4", xl: "3" }
                                      },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Institution",
                                            name: "institution"
                                          },
                                          model: {
                                            value: _vm.formDataF["institution"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "institution",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['institution']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isServiceAccount
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", lg: "4", xl: "3" }
                                      },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Service Account Name",
                                            name: "entityName"
                                          },
                                          model: {
                                            value: _vm.formDataF["entityName"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "entityName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['entityName']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    !_vm.isServiceAccount
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  "with-border": "",
                                  title: "Profile",
                                  expandable: ""
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "First Name",
                                            name: "profile.firstName"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "firstName"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "firstName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['firstName']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Last Name",
                                            name: "profile.lastName"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "lastName"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "lastName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['lastName']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-date", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Date of Birth",
                                            name: "profile.birthDate"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "birthDate"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "birthDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['birthDate']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.nationalityReload,
                                            props: props,
                                            "drop-down": "",
                                            "item-text": "ctyDsc",
                                            "item-value": "ctyCod",
                                            label: "Nationality",
                                            mandatory: "",
                                            name: "profile.nationality",
                                            "show-item-avatar": "",
                                            sortable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "nationality"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "nationality",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['nationality']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload": _vm.genderReload,
                                            props: props,
                                            "drop-down": "",
                                            label: "Gender",
                                            mandatory: "",
                                            name: "profile.gender",
                                            sortable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "gender"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "gender",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['gender']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isServiceAccount
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  "with-border": "",
                                  title: "Identity",
                                  expandable: ""
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _vm.isOfficerAccount || _vm.isUserAccount
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", lg: "6" } },
                                          [
                                            _c("asyent-form-text", {
                                              attrs: {
                                                "view-mode": _vm.viewMode,
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: props,
                                                label: "Badge No",
                                                name: "profile.badgeNO"
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["profile"][
                                                    "badgeNO"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF["profile"],
                                                    "badgeNO",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['profile']['badgeNO']"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.identityTypeReload,
                                            props: props,
                                            "drop-down": "",
                                            label: "Identity Type",
                                            mandatory: "",
                                            name: "profile.identityType",
                                            sortable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "identityType"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "identityType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['identityType']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.nationalityReload,
                                            props: props,
                                            "drop-down": "",
                                            "item-text": "ctyDsc",
                                            "item-value": "ctyCod",
                                            label: "Identity Nationality",
                                            mandatory: "",
                                            name: "profile.identityCountry",
                                            "show-item-avatar": "",
                                            sortable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "identityCountry"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "identityCountry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['identityCountry']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Identity No#",
                                            name: "profile.identityNo"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "identityNo"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "identityNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['identityNo']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-date", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Date of Issuance",
                                            name: "profile.identityDate"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "identityDate"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "identityDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['identityDate']"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-date", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Date of Expiry",
                                            name: "profile.identityExpiryDate"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "identityExpiryDate"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "identityExpiryDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['identityExpiryDate']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.isOfficerAccount
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: { title: "Traders", expandable: "" },
                                scopedSlots: _vm._u(
                                  [
                                    !_vm.isDisabled("traders") &&
                                    !props.disabled &&
                                    !props.readonly
                                      ? {
                                          key: "toolbar-actions",
                                          fn: function() {
                                            return [
                                              _c("asyent-button", {
                                                attrs: {
                                                  "button-icon": "mdi-plus",
                                                  "button-tooltip":
                                                    "Add Trader",
                                                  small: "",
                                                  text: ""
                                                },
                                                on: {
                                                  confirmed: function($event) {
                                                    return _vm.formDataF[
                                                      "traders"
                                                    ].push("")
                                                  }
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "* Mandatory to link your account with a trader"
                                          )
                                        ]),
                                        _vm._l(
                                          _vm.formDataF["traders"],
                                          function(item, index) {
                                            return [
                                              _c("asyent-form-text", {
                                                key: index,
                                                attrs: {
                                                  "view-mode": _vm.viewMode,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func":
                                                    _vm.isDisabled,
                                                  props: props,
                                                  label: "Trader No",
                                                  name: "traders"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    !_vm.isDisabled(
                                                      "traders"
                                                    ) &&
                                                    !props.disabled &&
                                                    !props.readonly
                                                      ? {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              true
                                                                ? _c(
                                                                    "asyent-button",
                                                                    {
                                                                      attrs: {
                                                                        disabled: false,
                                                                        "button-icon":
                                                                          "mdi-minus",
                                                                        "button-tooltip":
                                                                          "Delete",
                                                                        icon:
                                                                          "",
                                                                        "requires-confirmation":
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        confirmed: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.formDataF[
                                                                            "traders"
                                                                          ].splice(
                                                                            index,
                                                                            1
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      : null
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.formDataF["traders"][
                                                      index
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formDataF["traders"],
                                                      index,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formDataF['traders'][index]"
                                                }
                                              })
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isOfficerAccount && !_vm.isTraderAccount
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: { title: "Agents", expandable: "" },
                                scopedSlots: _vm._u(
                                  [
                                    !_vm.isDisabled("agents") &&
                                    !props.disabled &&
                                    !props.readonly
                                      ? {
                                          key: "toolbar-actions",
                                          fn: function() {
                                            return [
                                              _c("asyent-button", {
                                                attrs: {
                                                  "button-icon": "mdi-plus",
                                                  "button-tooltip": "Add Agent",
                                                  small: "",
                                                  text: ""
                                                },
                                                on: {
                                                  confirmed: function($event) {
                                                    return _vm.formDataF[
                                                      "agents"
                                                    ].push("")
                                                  }
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "* Mandatory to link your account with a trader (or traders)"
                                          )
                                        ]),
                                        _vm._l(
                                          _vm.formDataF["agents"],
                                          function(item, index) {
                                            return [
                                              _c("asyent-form-text", {
                                                key: index,
                                                attrs: {
                                                  "view-mode": _vm.viewMode,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func":
                                                    _vm.isDisabled,
                                                  props: props,
                                                  label: "Agent No",
                                                  name: "agents"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    !_vm.isDisabled("agents") &&
                                                    !props.disabled &&
                                                    !props.readonly
                                                      ? {
                                                          key: "append-outer",
                                                          fn: function() {
                                                            return [
                                                              true
                                                                ? _c(
                                                                    "asyent-button",
                                                                    {
                                                                      attrs: {
                                                                        disabled: false,
                                                                        "button-icon":
                                                                          "mdi-minus",
                                                                        "button-tooltip":
                                                                          "Delete",
                                                                        icon:
                                                                          "",
                                                                        "requires-confirmation":
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        confirmed: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.formDataF[
                                                                            "agents"
                                                                          ].splice(
                                                                            index,
                                                                            1
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      : null
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.formDataF["agents"][
                                                      index
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formDataF["agents"],
                                                      index,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formDataF['agents'][index]"
                                                }
                                              })
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "User Login", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Login Identifier",
                                        name: "loginIdentifier"
                                      },
                                      model: {
                                        value: _vm.formDataF["loginIdentifier"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "loginIdentifier",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['loginIdentifier']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Email Address",
                                        name: "emailAddress"
                                      },
                                      model: {
                                        value: _vm.formDataF["emailAddress"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "emailAddress",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['emailAddress']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Primary Mobile No",
                                        name: "primaryMobile"
                                      },
                                      model: {
                                        value: _vm.formDataF["primaryMobile"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "primaryMobile",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['primaryMobile']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                !_vm.viewMode
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Password",
                                            name:
                                              "credentialPasswordModel.credentialValue",
                                            type: "password"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "credentialPasswordModel"
                                              ]["credentialValue"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "credentialPasswordModel"
                                                ],
                                                "credentialValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['credentialPasswordModel']['credentialValue']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.viewMode
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Password Confirmation",
                                            name:
                                              "credentialPasswordModel.credentialChallenge",
                                            type: "password"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "credentialPasswordModel"
                                              ]["credentialChallenge"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF[
                                                  "credentialPasswordModel"
                                                ],
                                                "credentialChallenge",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['credentialPasswordModel']['credentialChallenge']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c("AccountsAddresses", {
                          attrs: {
                            "view-mode": _vm.viewMode,
                            "is-disabled-func": _vm.isDisabled,
                            readonly: _vm.isReadonly,
                            name: "addresses",
                            title: "Addresses"
                          },
                          model: {
                            value: _vm.formDataF["addresses"],
                            callback: function($$v) {
                              _vm.$set(_vm.formDataF, "addresses", $$v)
                            },
                            expression: "formDataF['addresses']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("asyent-form-select", {
                          attrs: {
                            "view-mode": _vm.viewMode,
                            "form-errors": _vm.formErrors,
                            "form-rules": _vm.formFieldsRules,
                            "is-disabled-func": _vm.isDisabled,
                            "items-reload": _vm.unitsReload,
                            "items-reload-params": _vm.formDataF.group,
                            props: props,
                            "enable-select-all": "",
                            label: "Access Units",
                            multiple: "",
                            name: "units",
                            sortable: "",
                            title: "Access Units"
                          },
                          model: {
                            value: _vm.formDataF["units"],
                            callback: function($$v) {
                              _vm.$set(_vm.formDataF, "units", $$v)
                            },
                            expression: "formDataF['units']"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("AccountsAttributes", {
                          attrs: {
                            "view-mode": _vm.viewMode,
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": ["Name", "Value"],
                            "item-values": ["code", "value"],
                            readonly: _vm.isReadonly,
                            name: "attributes",
                            title: "Attributes"
                          },
                          model: {
                            value: _vm.formDataF["attributes"],
                            callback: function($$v) {
                              _vm.$set(_vm.formDataF, "attributes", $$v)
                            },
                            expression: "formDataF['attributes']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Remarks", expandable: "" } },
                          [
                            _c("asyent-form-textarea", {
                              attrs: {
                                "view-mode": _vm.viewMode,
                                "form-errors": _vm.formErrors,
                                "form-rules": _vm.formFieldsRules,
                                "is-disabled-func": _vm.isDisabled,
                                props: props,
                                flat: "",
                                label: "User Account Remarks",
                                "no-label": "",
                                name: "remarks",
                                "no-resize": ""
                              },
                              model: {
                                value: _vm.formDataF["remarks"],
                                callback: function($$v) {
                                  _vm.$set(_vm.formDataF, "remarks", $$v)
                                },
                                expression: "formDataF['remarks']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }